const modal = {

	el: {
		doc: $(document),
		body: $('body'),
		header: $('.header-wrapper'),
		modalBtnOpen: $('.js-modal-open'),
		modalBtnClose: $('.js-modal-close'),
		modalContainer: $('.modal')
	},

	events() {
		this.el.modalContainer.hide()

		this.el.modalBtnOpen.on('click', e => {
			e.preventDefault()
			this.el.header.css('z-index', '-1')
			this.el.body.css('overflow', 'hidden')
			this.el.modalContainer.addClass('no-scroll').show()
		})

		this.el.modalBtnClose.on('click', e => {
			e.preventDefault()
			this.el.header.removeAttr('style')
			this.el.body.css('overflow', 'hidden auto')
			this.el.modalContainer.removeClass('no-scroll').hide()
		})

		this.el.doc.on('click', e => {			
			if (!$(e.target).closest('.js-modal-open, .modal-table-wrap').length) {
				this.el.header.removeAttr('style')
				this.el.body.css('overflow', 'hidden auto')
				this.el.modalContainer.removeClass('no-scroll').hide()
			}
		})

	},

	init() {
		this.events()
	}

}

modal.init()