/* global Swiper */
const sliders = {
	el: {
		win: $(window),
		html: $('html'),
		slider: $('.js-slider'),
		sliderHp: $('.slider-hp'),
		header: $('.header')
	},

	events(el) {
		const sliderCentered = $(el).data('slider-centered'),
			sliderDelay = $(el).data('slider-delay'),
			sliderLoop = $(el).data('slider-loop'),
			sliderAutoPlay = $(el).data('slider-autoplay'),
			sliderSizeWide = $(el).data('slider-wide'),
			sliderSizeLarge = $(el).data('slider-large'),
			sliderSizeMedium = $(el).data('slider-medium'),
			sliderSizeSmall = $(el).data('slider-small'),
			sliderPaginationType = $(el).data('slider-pagination-type'),
			sliderSpaceBetween = $(el).data('slider-space-between'),
			sliderOnInteraction = $(el).data('slider-on-interaction'),
			sliderNoSwipe = $(el).data('slider-no-swipe'),
			sliderFreeMode = $(el).data('slider-freemode'),
			sliderFreeModeSticky = $(el).data('slider-freemode-sticky'),
			sliderKeyboard = $(el).data('slider-keyboard'),
			sliderMouseWheel = $(el).data('slider-mousewheel')

		new Swiper(el, {
			slidesPerView: sliderSizeSmall ? sliderSizeSmall : 1,
			centeredSlides: sliderCentered ? sliderCentered : false,
			loop: sliderLoop ? sliderLoop : false,
			spaceBetween: sliderSpaceBetween ? sliderSpaceBetween : 0,
			noSwiping: sliderNoSwipe ? sliderNoSwipe : false,
			freeMode: sliderFreeMode ? sliderFreeMode : false,
			freeModeSticky: sliderFreeMode && sliderFreeModeSticky ? sliderFreeModeSticky : false,
			slideToClickedSlide: sliderFreeMode ? true : false,
			watchOverflow: true,
			mousewheel: sliderFreeMode ? {
				releaseOnEdges: true
			} : sliderMouseWheel ? sliderMouseWheel : false,

			keyboard: {
				enabled: sliderKeyboard ? sliderKeyboard : false
			},

			navigation: {
				nextEl: $(el).siblings('.swiper-button-next'),
				prevEl: $(el).siblings('.swiper-button-prev')
			},

			pagination: {
				el: $(el).find('.swiper-pagination'),
				clickable: sliderPaginationType ? false : true,
				type: sliderPaginationType ? sliderPaginationType : 'bullets' //"bullets", "fraction", "progressbar" or "custom"  (default:"bullets")
			},

			autoplay: sliderAutoPlay === false ? sliderAutoPlay : sliderAutoPlay === true ? {
				delay: sliderDelay ? sliderDelay : 5000,
				disableOnInteraction: sliderOnInteraction ? sliderOnInteraction : false
			} : '',

			breakpoints: {
				768: { //tablet up
					slidesPerView: sliderSizeMedium ? sliderSizeMedium : sliderSizeSmall ? sliderSizeSmall : 1
				},
				1024: { //small desktop up
					slidesPerView: sliderSizeLarge ? sliderSizeLarge : sliderSizeMedium ? sliderSizeMedium : 1
				},
				1200: { // desktop up
					slidesPerView: sliderSizeWide ? sliderSizeWide : sliderSizeLarge ? sliderSizeLarge : 1
				}
			},

			on: {
				init: () => {
					setTimeout(() => {
						!this.el.html.hasClass('ie11') ? window.dispatchEvent(new Event('resize')) : ''
						this.el.sliderHp.css('height', this.el.win.height() - this.el.header.height())
					}, 200)
				},
				resize: () => {
					setTimeout(() => {
						this.el.sliderHp.css('height', this.el.win.height() - this.el.header.height())
					}, 200)
				}
			}
		})
	},

	init() {
		this.el.slider.each((i, el) => {
			$(el).find('.swiper-slide').length > 1 ? this.events(el) : ''
		})
	}
}
sliders.init()