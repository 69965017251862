const video = {
	el: {
		win: $(window),
		doc: $(document),
		header: $('.header'),
		videoSection: $('.video'),
		videoPlayer: $('video'),
		videoBtnPlay: $('.js-play-video'),
		videoBtnPause: $('.js-pause-video')
	},

	FxTabSwitch(el) {
		// Set the name of the hidden property and the change event for visibility
		let hidden, visibilityChange
		if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
			hidden = 'hidden'
			visibilityChange = 'visibilitychange'
		} else if (typeof document.msHidden !== 'undefined') { // IE support
			hidden = 'msHidden'
			visibilityChange = 'msvisibilitychange'
		} else if (typeof document.webkitHidden !== 'undefined') { // Chrome support
			hidden = 'webkitHidden'
			visibilityChange = 'webkitvisibilitychange'
		}

		// Handle page visibility change   
		this.el.doc.on(visibilityChange, () => document[hidden] ? el.pause() : this.el.videoPlayer.is(':visible') && this.el.videoBtnPlay.is(':hidden') ? el.play() : '')
	},

	events() {

		if (!this.el.videoPlayer.length) return

		const videoPlayer = this.el.videoPlayer.get(0)
		let counter = 0

		this.el.videoBtnPlay.on('click', e => {
			if (videoPlayer.paused) {
				videoPlayer.play()
				$(e.currentTarget).hide()
				counter = 1
			}
		})

		this.el.videoBtnPause.on('click', e => {
			if (!videoPlayer.paused) {
				videoPlayer.pause()
				$(e.currentTarget).animate({
					'bottom': '-5rem',
					'opacity': 0
				}, 100)
				this.el.videoBtnPlay.fadeIn()
				counter--
			}
		})

		this.el.videoSection
			.on('mouseenter touchstart', () => {
				if (this.el.videoBtnPlay.is(':hidden')) {
					counter++
				}
				if (!videoPlayer.paused && counter > 1) {
					this.el.videoBtnPause.animate({
						'bottom': 0,
						'opacity': 1
					}, 100)
				}
			})
			.on('mouseleave', () => {
				this.el.videoBtnPause.animate({
					'bottom': '-5rem',
					'opacity': 0
				}, 100)
			})

		videoPlayer.onended = e => {
			e.currentTarget.load()
			this.el.videoBtnPlay.show()
		}

		this.FxTabSwitch(videoPlayer)

		this.el.videoSection.on('contextmenu', () => {
			return false
		})

	},

	init() {
		this.events()
	}
}

video.init()