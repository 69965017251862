const accordion = {
	el: {
		accordionTitle: $('.js-accordion'),
		accordionContent: $('.accordion-content')
	},

	events() {
		this.el.accordionContent.hide()

		this.el.accordionTitle.on('click', e => {
			$(e.currentTarget).toggleClass('active').next().slideToggle().parent().siblings().find('.accordion-title').removeClass('active').next().slideUp()
		})
	},

	init() {
		this.events()
	}
}

accordion.init()