const picker = {
	el: {
		pickerContainer: $('.media-picker'),
		pickerColorItem: $('.media-picker-item'),
		imagePlaceholder: $('.img-replace'),
		buttonsContainer: $('.media-buttons')
	},

	fxRemoveButtonsText() {
		this.el.buttonsContainer.find('button').not('.add-to-cart-button').text('')
	},

	events() {

		this.el.pickerColorItem.each((i, el) => $(el).is(':checked') ? $(el).parent().addClass('active') : '')

		this.el.pickerColorItem.on('change', e => {
			const originalImage = $(e.currentTarget).closest('.media-picker').find('.img-replace'),
				originalImageBg = $(e.currentTarget).closest('.media-bg.media-picker'),
				selectedImage = $(e.currentTarget).data('id')

			originalImage.attr('src', selectedImage)

			$(e.currentTarget).parent().addClass('active').siblings().removeClass('active')

			if (!originalImageBg.length) return

			originalImageBg.css('background-image', `url('${selectedImage}')`)
		})
	},

	init() {
		this.events()
		this.fxRemoveButtonsText()
	}

}

picker.init()