const menu = {
	el: {
		menuButton: $('.menu-button .trigger, .personal-button .trigger'),
		closeMenu: $('.close-menu'),
		header: $('.header-wrapper')
	},
	init() {
		this.el.menuButton.add(this.el.closeMenu).on('click', () => this.el.header.toggleClass('active'))

	}
}

menu.init()