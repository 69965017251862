const scrollToElement = {
	el: {
		html: $('html'),
		body: $('body'),
		btnScroll: $('.js-scrollto'),
		scrolledElement: $('.scrolled-element'),
		header: $('.header')
	},

	fxScrollTo(btnScrollTo, elementToScroll, duration) {
		btnScrollTo.on('click', e => {
			e.preventDefault()
			this.el.html.add(this.el.body).animate({
				scrollTop: elementToScroll.offset().top - (this.el.header ? this.el.header.height() : 0)
			}, duration)
		})
	},

	events() {
		this.fxScrollTo(this.el.btnScroll, this.el.scrolledElement, 1000)
	},
	
	init() {
		this.events()
	}
}

scrollToElement.init()